import { apiService } from './api.service';
import {
  SubscriptionsData,
  SubscriptionsFormData,
  SubscriptionsPayload,
  SubscriptionsResponse,
} from '../models';

export const NO_GROUP_NAME = 'no-group';

export class ConsentsService {
  async loadConsents(): Promise<{
    subscriptionsData: SubscriptionsData;
    email: string;
  }> {
    const response = await apiService.getPersonSubscriptions();
    return this.convertResponseToSubscriptionData(response);
  }

  async saveConsents(consents: SubscriptionsFormData): Promise<void> {
    const payload: SubscriptionsPayload = {
      encryptedPayload: apiService.payload,
      subscriptions: Object.entries(consents).reduce((acc, [key, value]) => {
        acc.push({
          code: key,
          given: value,
        });
        return acc;
      }, [] as { code: string; given: boolean }[]),
    };
    return await apiService.post('api/v1/PersonSubscriptions', payload);
  }

  private convertResponseToSubscriptionData(response: SubscriptionsResponse): {
    subscriptionsData: SubscriptionsData;
    email: string;
  } {
    const result = {
      email: response.identifier,
      subscriptionsData: response.subscriptions.reduce((acc, subscription) => {
        let groupName = subscription.groupName;
        if (!groupName) {
          groupName = NO_GROUP_NAME;
        }
        if (acc[groupName]) {
          acc[groupName].push(subscription);
        } else {
          acc[groupName] = [subscription];
        }
        return acc;
      }, {} as SubscriptionsData),
    };

    return result;
  }
}

export const consentsService = new ConsentsService();
