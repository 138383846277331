import { SUBSCRIPTIONS_DATA_MOCK } from '../mocks/api/subscriptions-data.mock';
import { BaseResponse, Config, SubscriptionsResponse } from '../models';

export class ApiService {
  payload = '';
  baseUrl = '';

  constructor() {
    this.baseUrl =
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? this.getBaseUrl(process.env.REACT_APP_CUSTOMER as string)
        : window.location.origin;
    const urlParams = new URLSearchParams(window.location.search);
    this.payload = (urlParams.get('payload') as string) || '';
  }

  getBaseUrl(customerCode: string): string {
    return `https://${customerCode}-subscription-acc.ireckonu.com`;
  }

  async getCustomerConfig(): Promise<Config> {
    if (process.env.REACT_APP_USE_LOCAL_CONFIG === 'true') {
      return await import(
        `../mocks/configs/${process.env.REACT_APP_CUSTOMER}.json`
      );
    }

    return await this.get<Config>('Styles');
  }

  async getPersonSubscriptions(): Promise<SubscriptionsResponse> {
    if (process.env.REACT_APP_USE_MOCKED_API === 'true') {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(SUBSCRIPTIONS_DATA_MOCK);
        }, 3000);
      });
    }

    return await this.get<SubscriptionsResponse>(
      `PersonSubscriptions?payload=${this.payload}`,
    );
  }

  private async get<T>(path: string): Promise<T> {
    let response = await fetch(`${this.baseUrl}/api/v1/${path}`, {
      headers: {
        Authorization: this.payload,
      },
    });

    const data = await response.json();
    if (response.status >= 300 || !data.success) {
      throw new Error(data.errors[0]);
    }
    return data.data;
  }

  async post<T, P>(path: string, body: P): Promise<T> {
    if (process.env.REACT_APP_USE_MOCKED_API === 'true') {
      const mockResponse = {
        status: 'success',
        data: body,
      };

      return new Promise<T>((resolve) => {
        setTimeout(() => {
          resolve(mockResponse as T);
        }, 3000);
      });
    }

    const response = await fetch(
      `${this.baseUrl}/${path}?payload=${this.payload}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.payload,
        },
        body: JSON.stringify(body),
      },
    );
    const data = await response.json();
    if (response.status >= 300 || !data.success) {
      throw new Error(data.errors[0]);
    }
    return data.data;
  }
}

export const apiService = new ApiService();
